<div class="row">
    <div class="col-md-2 col-2">
        <figure class="image">
            <a href="https://www.dfki.de/web/forschung/forschungsbereiche/agenten-und-simulierte-realitaet/" target="_blank" rel="noopener noreferrer" title="Deutsches Forschungszentrum für Künstliche Intelligenz">
            <img title="Deutsches Forschungszentrum für Künstliche Intelligenz" alt="DFKI Logo" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fde%2Fthumb%2F4%2F4e%2FDFKI_Logo.svg%2F1280px-DFKI_Logo.svg.png&f=1&nofb=1" width="386" height="77"> 
            </a>
          </figure>
        <div class="row" style="margin-top: 143rem;">
            <h1 style="margin-top: 0rem; font-size: 18px; margin-left: 1rem;">
                <a routerLink = "/" >Home</a>
              </h1>
            <h1 style="margin-top: 0rem; font-size: 18px; margin-left: 1rem;">
                <a routerLink = "/impressum" >Impressum</a>
              </h1>
            <h1 style="margin-top: 0rem; font-size: 18px; margin-left: 1rem;">
              <a routerLink = "/datenschutzerklaerung">Datenschutzerklärung</a>
            </h1>
        </div>
    </div>
  
    <div class="col-md-8 col-8">
        <div>
            <h1 style="font-size: 30px; margin-top: 2rem;margin-left: 35rem;">Datenschutzerklärung</h1>
        </div>
        <div style="margin-top: 5rem">
            <p>Das DFKI (Deutsches Forschungszentrum für Künstliche Intelligenz) und seine Mitarbeiter/-innen setzen sich ziel- und risikoorientiert für die informationelle Selbstbestimmung und das Grundrecht auf Schutz personenbezogener Daten ein. In dieser Datenschutzerklärung informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten bei Besuch und Benutzung unserer Webseite.</p>
<h4>Verantwortlicher</h4>
<p>Deutsches Forschungszentrum für Künstliche Intelligenz (DFKI)<br />
Telefon: +49 (0)631 / 205 75-0, E-Mail: <a href="mailto:info@dfki.de">info@dfki.de</a>, <a href="https://www.dfki.de/web/legal-info-de">Impressum</a></p>
<h4>Datenschutzbeauftragter</h4>
<p>Telefon: +49 (0)631 / 205 75-0, E-Mail: <a href="mailto:datenschutz@dfki.de">datenschutz@dfki.de</a></p>
<h4>Zweck der Verarbeitung</h4>
<ul>
<li>Bereitstellung des Informationsangebotes im Rahmen der Öffentlichkeitsarbeit des DFKI</li>
<li>Kontaktaufnahme und Korrespondenz mit Besuchern und Benutzern</li>
</ul>
<h4>Anonyme und geschützte Benutzung</h4>
<p>Der Besuch und die Benutzung unserer Webseite sind anonym. Personenbezogene Daten werden auf unserer Webseite nur im technisch notwendigen Umfang erhoben. Die verarbeiteten personenbezogenen Daten werden außer aufgrund konkreter gesetzlicher Verpflichtungen nicht an Dritte übermittelt oder in anderer Weise offengelegt. Wir binden innerhalb unseres Informationsangebots keine Inhalts- oder Serviceangebote von Drittanbietern ein.</p>
<p>Bei der Benutzung unserer Webseite ist die Datenübertragung im Internet durch ein als sicher anerkanntes Verschlüsselungsverfahren gegen Kenntnisnahme Dritter geschützt und kann somit nicht auf einfache Weise beobachtet oder verfälscht werden kann.</p>
<h4>Zugriffsdaten</h4>
<p>Bei jedem Zugriff auf unsere Webseite werden Nutzungs-, Verkehrs- und Verbindungsdaten erhoben, vorübergehend in einer Protokolldatei gespeichert und regelmäß nach 90 Tagen gelöscht.</p>
<p>Über jeden Zugriff/Abruf werden folgende Daten gespeichert:</p>
<ul>
<li>IP-Adresse</li>
<li>übermittelte User-Agent Information (insb. Typ/Version von Webbrowser, Betriebssystem, usw.)</li>
<li>übermittelte Referrer Information (URL der verweisenden Seite)</li>
<li>Datum und Uhrzeit des Zugriffs/Abrufs</li>
<li>übermittelte Zugriffsmethode/Funktion</li>
<li>übermittelte Eingabewerte (Suchbegriffe, usw.)</li>
<li>aufgerufene Seite bzw. abgerufene Datei</li>
<li>übertragene Datenmenge</li>
<li>Status der Verarbeitung des Zugriffs/Abrufs</li>
</ul>
<p>Die Verarbeitung der Zugriffsdaten ist rechtmäßig, weil sie zur Wahrung berechtigter Interessen des DFKI erforderlich ist. Die berechtigten Interessen des DFKI sind die Anpassung und Optimierung des Informationsangebots und die Ermittlung, Aufdeckung von Verfolgung von rechtswidrigen Handlungen im Zusammenhang mit der Benutzung der Webseite.</p>
<p>Die gespeicherten Datensätze können statistisch ausgewertet werden, um unser Informationsangebot an die Bedürfnisse unserer Besucher anpassen und optimieren zu können. Techniken, die es ermöglichen, das Zugriffsverhalten der Benutzer nachzuvollziehen (Tracking), werden nicht eingesetzt. Die Erstellung von Benutzerprofilen und eine darauf beruhende automatisierte Entscheidungsfindung ist ausgeschlossen.</p>
<p>Die gespeicherten Datensätze sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung mit anderen Datenquellen wird grundsätzlich nicht vorgenommen. Die gespeicherten Daten können jedoch geprüft und mit anderen Datenquellen zusammengeführt werden, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Benutzung bekannt werden.</p>
<h4>Cookies</h4>
<p>Auf unserer Webseite verwenden wir sogenannte Cookies. Cookies sind kleine Dateien, die von Ihrem Webbrowser gespeichert werden. Die auf unserer Webseite verwendeten Cookies richten auf Ihrem Rechner keinen Schaden an und enthalten keine Schadsoftware. Sie ermöglichen eine benutzerfreundliche und effektive Nutzung unserer Webseite. Wir verwenden Cookies nicht für Werbezwecke.</p>
<p>Wir übertragen an Ihren Webbrowser temporäre Session Cookies. Sie sind nur für die Dauer Ihres Besuches auf unserer Webseite gültig und sie haben außerhalb unserer Webseite keine Bedeutung. Die temporären Cookies werden verwendet, um Ihre Sitzung während Ihres Besuches mit einer eindeutigen Nummer zu identifizieren und unsere Inhalte in der von Ihnen bevorzugten Sprache zu übertragen. Nach Ende Ihres Besuches können Sie die Session Cookies durch Beendigung Ihres Webbrowsers löschen.</p>
<p>Wir übertragen an Ihren Webbrowser auch permanente Cookies mit einer Gültigkeitsdauer von höchstens 365 Tagen. Wir verwenden diese Cookies ausschließlich dazu, bei Ihrem nächsten Besuch unserer Webseite Ihre Einstellungen für die Art der Darstellung (normal, invertiert) und für die Schriftgröße zu beachten. Weiterhin wird vermerkt, ob Sie die Information über die Verwendung von Cookies in Ihrem Webbrowser zur Kenntnis genommen haben.</p>
<p>Sie können Ihren Webbrowser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben bzw. die Annahme von Cookies für bestimmte Fälle oder generell ausschließen. Bereits gespeicherte Cookies können Sie in den Einstellungen Ihres Webbrowsers löschen. Sie können auch das automatische Löschen von Cookies beim Beenden Ihres Webbrowsers einstellen. Bei der Deaktivierung von Cookies kann die Funktionalität unserer Webseite eingeschränkt sein. In jedem Fall steht unser Informationsangebot in seinem vollem Umfang zur Verfügung.</p>
<h4>Social Media</h4>
<p>Wir betten keine Social Media Plugins in unserer Webseite ein. Wenn Sie unsere Webseite besuchen, werden daher keine Daten an Social Media Services übermittelt. Eine Profilbildung durch Dritte ist somit ausgeschlossen. Sie haben jedoch die Möglichkeit, direkt zu unseren Informationsangeboten auf Facebook, Twitter und YouTube zu wechseln. Für die Benutzung dieser Dienste verweisen wir auf die Geschäftsbedingungen und die Datenschutzbestimmungen der jeweiligen Betreiber. Wir verarbeiten Ihre personenbezogene Daten innerhalb der sozialen Netzwerke soweit Sie Beiträge einstellen, Nachrichten versenden oder auf andere Weise mit uns kommunizieren.</p>
<h4>Korrespondenz</h4>
<p>Sie haben die Möglichkeit, uns per E-Mail zu kontaktieren. Ihre E-Mail-Adresse und andere personenbezogene Kontaktdaten verwenden wir für die Korrespondenz mit Ihnen. Aufgrund gesetzlicher Verpflichtung wird jede E-Mail-Korrespondenz archiviert. Soweit zur Wahrung unserer berechtigten Interessen erforderlich, können Ihre E-Mail-Adresse und andere personenbezogene Kontaktdaten aus der Korrespondenz in unserer Kontaktdatenbank gespeichert werden. In diesem Fall werden Sie eine entsprechende Information über die Verarbeitung Ihrer Kontaktdaten erhalten.</p>
<h4>Auskunft und Intervention</h4>
<p>Neben der Information in dieser Datenschutzerklärung haben Sie das Recht auf Auskunft über Ihre personenbezogenen Daten. Zur Gewährleistung einer fairen Verarbeitung bestehen für Sie die folgenden Interventionsrechte:</p>
<ul>
<li>Das Recht auf Berichtigung und Vervollständigung Ihrer personenbezogenen Daten</li>
<li>Das Recht auf Löschung Ihrer personenbezogenen Daten</li>
<li>Das Recht auf Einschränkung der Verarbeitung Ihrer personenbezogenen Daten</li>
<li>Das Recht auf Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten aus Gründen Ihrer besonderen Situation</li>
</ul>
<p>Für die Ausübung Ihrer Rechte wenden Sie sich bitte an unseren Datenschutzbeauftragten.</p>
<h4>Recht auf Beschwerde</h4>
<p>Sie haben das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung Ihrer personenbezogenen Daten gegen gesetzliche Datenschutzvorschriften verstößt.</p>
        </div>
    </div>
    <div class="col-md-2 col-2">

    </div>

</div>

