<div class="row">
    <div class="col-md-2 col-2">
        <figure class="image">
            <a href="https://www.dfki.de/web/forschung/forschungsbereiche/agenten-und-simulierte-realitaet/" target="_blank" rel="noopener noreferrer" title="Deutsches Forschungszentrum für Künstliche Intelligenz">
            <img title="Deutsches Forschungszentrum für Künstliche Intelligenz" alt="DFKI Logo" src="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fupload.wikimedia.org%2Fwikipedia%2Fde%2Fthumb%2F4%2F4e%2FDFKI_Logo.svg%2F1280px-DFKI_Logo.svg.png&f=1&nofb=1" width="386" height="77"> 
            </a>
          </figure>
          <div class="row" style="margin-top: 65rem;">
            <h1 style="margin-top: 0rem; font-size: 18px; margin-left: 1rem;">
                <a routerLink = "/" >Home</a>
              </h1>
            <h1 style="margin-top: 0rem; font-size: 18px; margin-left: 1rem;">
                <a routerLink = "/impressum" >Impressum</a>
              </h1>
            <h1 style="margin-top: 0rem; font-size: 18px; margin-left: 1rem;">
              <a routerLink = "/datenschutzerklaerung">Datenschutzerklärung</a>
            </h1>
          </div>
    </div>
  
    <div class="col-md-8 col-8">
        <div>
            <h1 style="font-size: 30px; margin-top: 2rem;margin-left: 35rem;">Impressum</h1>
        </div>
        <div style="margin-top: 5rem">
            <h2>Angaben gemäß §5 TMG:</h2>
            <h3><strong>Deutsches Forschungszentrum für Künstliche Intelligenz GmbH, DFKI</strong></h3>
            <h4>Briefadresse</h4>
            <p>Postfach 20 80<br />
            D-67608 Kaiserslautern</p>
            <p><strong>E-Mail</strong>: <a href="mailto:info@dfki.de">info@dfki.de</a></p>
            <h4>Standort Kaiserslautern</h4>
            <p>Trippstadter Straße 122<br />
            D-67663 Kaiserslautern<br />
            Telefon: +49 (0)631 / 205 75-0<br />
            Telefax: +49 (0)631 / 205 75-5030</p>
            <h4>Standort Saarbrücken</h4>
            <p>Campus D3_2<br />
            Stuhlsatzenhausweg 3<br />
            D-66123 Saarbrücken<br />
            Telefon: +49 (0)681 / 857 75-0<br />
            Telefax: +49 (0)681 / 857 75-5341</p>
            <h4>Standort Bremen</h4>
            <p>Robert-Hooke-Straße 1<br />
            D-28359 Bremen<br />
            Telefon: +49 (0)421 / 178 45-0<br />
            Telefax: +49 (0)421 / 178 45-4150</p>
            <h4>DFKI Projektbüro Berlin</h4>
            <p>Alt-Moabit 91c<br />
            D-10559 Berlin<br />
            Tel: +49 (0)30 / 238 95-0</p>
            <p>&nbsp;</p>
            <h4>Vertretungsberechtigte Geschäftsführer</h4>
            <p>Prof. Dr. Dr. h.c. mult. Wolfgang Wahlster (Vorsitzender),<br />
            Dr. Walter Olthoff</p>
            <h4>Vorsitzender des Aufsichtsrates</h4>
            <p>Prof. Dr. h.c. Hans A. Aukes</p>
            <p>Registergericht: Amtsgericht Kaiserslautern<br />
            Registernummer: HRB 2313</p>
            <p><strong>ID-Nummer:</strong> DE 148 646 973</p>
            <h4>Weitere Informationen</h4>
            <p>Weitere rechtliche Informationen zur Speicherung ihrer Daten finden sie in unserer <a href="/datenschutzerklaerung">Datenschutzerklärung</a></p>
        </div>
    </div>
    <div class="col-md-2 col-2">

    </div>

</div>
