import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatenschutzerklaerungComponent } from './datenschutzerklaerung/datenschutzerklaerung.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';



const routes: Routes = [
  {path: '', component:HomeComponent},  
  {path: 'impressum', component:ImpressumComponent},
  {path: 'datenschutzerklaerung', component:DatenschutzerklaerungComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

